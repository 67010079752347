<template>
  <div id="catalog-product-edit">
    <div class="loading" v-if="isLoading">
      <div class="header">Catalog Product</div>
      <div class="is-size-4">Loading</div>
      <b-progress></b-progress>
    </div>
    <div class="not-loading" v-else>
      <nav class="breadcrumb" v-if="product">
        <ul>
          <li>
            <router-link class="p-0 m-0" to="/catalog-product"><a>Catalog Product</a></router-link>
          </li>
          <li class="is-active">
            <router-link class="p-0 m-0" :to="`/catalog-product/${id}`">
              <a>{{ isNew ? 'New Product' : `${product.name}` | truncate(30) }}</a>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="header">Catalog Product</div>
      <b-notification v-if="isCopy" type="is-info is-light" :closable="false">
        NOTE: The catalog product has not been saved. You must press "Create" below to create a copy of the original
        product.
      </b-notification>
      <add-product-area-modal
        :show-modal="isProductAreaModalActive"
        :product-area="{}"
        @close="isProductAreaModalActive = false"
        @submit="createProductArea"
      ></add-product-area-modal>
      <b-modal
        v-model="isProductCategoryModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        :full-screen="isFullScreen"
      >
        <template #default>
          <form class="modal-card" @submit.prevent="createProductCategory">
            <header class="modal-card-head">New Category</header>
            <section class="modal-card-body">
              <div class="columns">
                <div class="column">
                  <b-field label="Name / Tag">
                    <b-input type="text" v-model="productCategoryToCreate" placeholder="" required></b-input>
                  </b-field>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-small is-primary" type="submit">Create</button>
              <b-button
                size="is-small"
                label="Cancel"
                type="is-danger"
                @click="isProductCategoryModalActive = false"
              ></b-button>
            </footer>
          </form>
        </template>
      </b-modal>
      <b-modal
        v-model="isAddLineItemActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        :full-screen="isFullScreen"
      >
        <template #default>
          <form class="modal-card" @submit.prevent="addCatalogItem">
            <header class="modal-card-head">Add Catalog Item</header>
            <section class="modal-card-body">
              <div class="columns">
                <div class="column is-fullwidth">
                  <b-field label="search">
                    <b-autocomplete
                      v-model="searchLineItems"
                      :data="lineItemsList"
                      :loading="$asyncComputed.lineItemsList.updating"
                      icon="search"
                      clearable
                      append-to-body
                      field="name"
                      @select="setSelected"
                    >
                      <template #empty>No results found at all</template>
                    </b-autocomplete>
                  </b-field>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button
                native-type="submit"
                size="is-small"
                label="Add"
                type="is-primary"
                :disabled="!catalogItemIsSet"
              ></b-button>
              <b-button size="is-small" label="Cancel" type="is-danger" @click="isAddLineItemActive = false"></b-button>
            </footer>
          </form>
        </template>
      </b-modal>
      <form @submit.prevent="() => save()">
        <div class="sub-header">General</div>
        <div class="columns">
          <div class="column">
            <b-field>
              <b-tooltip
                label="If the product is not active you are not able to find it as a selection in a work order."
                position="is-right"
                multilined
              >
                <b-switch v-model="product.status" true-value="Active" false-value="Inactive" :disabled="!userCanEdit">
                  Active
                </b-switch>
              </b-tooltip>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Name">
              <b-input type="text" v-model="product.name" placeholder="" :disabled="!userCanEdit" required></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Specification" expanded>
              <b-select v-model="product.specification" expanded required :disabled="!isNew || !userCanEdit">
                <option v-for="specification in specifications" :value="specification.value" :key="specification.value">
                  {{ specification.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column" v-if="!(isServiceZone || isAssessmentFee || isDiscount)">
            <b-field label="Type" expanded>
              <b-select v-model="product.catalogProductType" :disabled="!userCanEdit" expanded>
                <option v-for="catalogType in catalogTypes" :value="catalogType.value" :key="catalogType.value">
                  {{ catalogType.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Catalog">
              <b-select
                v-model="product.catalogType"
                expanded
                required
                :disabled="(!isNew && isCostCode) || !userCanEdit"
                :loading="$asyncComputed.types.updating"
              >
                <option v-for="type in types" :value="{ id: type.id, name: type.name }" :key="type.id">
                  {{ type.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column" v-if="isServiceZone || isAssessmentFee">
            <b-field label="Duration (hours)">
              <b-numberinput
                min="0"
                step=".1"
                v-model="product.duration"
                icon="clock"
                placeholder=""
                required
                :disabled="!userCanEdit"
              ></b-numberinput>
            </b-field>
          </div>
        </div>
        <div class="columns is-multiline" v-if="!(isServiceZone || isAssessmentFee || isDiscount)">
          <div class="column is-half">
            <b-field label="Product Area">
              <b-select
                v-model="product.productArea"
                expanded
                required
                :loading="$asyncComputed.productAreas.updating"
                :disabled="!product.catalogType.id || !userCanEdit"
              >
                <option v-for="(area, key) in productAreas" :value="{ id: area.id, label: area.label }" :key="key">
                  {{ area.label }}
                </option>
              </b-select>
              <p class="control">
                <b-button
                  type="is-primary"
                  label="Add"
                  :disabled="!userCanEditProductArea"
                  @click="isProductAreaModalActive = true"
                ></b-button>
              </p>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Product Category">
              <b-select
                v-model="product.productCategory"
                expanded
                required
                :loading="$asyncComputed.productAreas.updating"
                :disabled="!product.productArea || !userCanEdit"
              >
                <option v-for="(category, key) in areaCategories" :value="category" :key="key">
                  {{ category }}
                </option>
              </b-select>
              <p class="control">
                <b-button
                  type="is-primary"
                  label="Add"
                  :disabled="!product.productArea || !userCanEdit"
                  @click="isProductCategoryModalActive = true"
                ></b-button>
              </p>
            </b-field>
          </div>
          <div class="column is-half" v-if="!isCostCode">
            <b-field label="Duration (hours)">
              <b-numberinput
                min="0"
                step=".1"
                v-model="product.duration"
                icon="clock"
                placeholder=""
                required
                :disabled="!userCanEdit"
              ></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half" v-if="!isCostCode">
            <b-field label="Quality" expanded>
              <b-select v-model="product.quality" :disabled="!userCanEdit" expanded>
                <option v-for="quality in qualities" :value="quality.value" :key="quality.value">
                  {{ quality.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <template v-if="isCostCode">
            <div class="column is-12">
              <div class="is-size-4">Associated Companies</div>
            </div>
            <div class="column is-half">
              <b-dropdown
                multiple
                aria-role="list"
                v-model="product.companies"
                :disabled="!userCanEdit"
                expanded
                scrollable
              >
                <template #trigger>
                  <b-button type="is-primary" icon-right="caret-down">
                    Selected ({{ product.companies.length }})
                  </b-button>
                </template>
                <b-dropdown-item
                  v-for="company in companies"
                  :value="company.code"
                  :key="company.code"
                  aria-role="listitem"
                >
                  <span>{{ company.code }} - {{ company.company }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="column is-half">
              <b-taglist>
                <b-tag v-for="company in product.companies" type="is-info" :key="company">{{ company }}</b-tag>
              </b-taglist>
            </div>
          </template>
          <div class="column is-12">
            <b-field label="Training Link">
              <b-input type="url" :disabled="!userCanEdit" v-model="product.trainingLink"></b-input>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Specific Language">
              <b-input type="textarea" :disabled="!userCanEdit" v-model="product.specificLanguage"></b-input>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Warranty Status">
              <b-input type="textarea" :disabled="!userCanEdit" v-model="product.warrantyStatus"></b-input>
            </b-field>
          </div>
          <div class="column is-half" v-if="isCostCode">
            <b-field label="Cost Code #">
              <b-input type="text" :disabled="!userCanEdit" v-model="product.costCodeNumber"></b-input>
            </b-field>
          </div>
        </div>
        <div class="block" v-if="showItems">
          <div class="header-container">
            <div class="sub-header">Items</div>
            <b-button
              class="button"
              icon-left="plus"
              :disabled="!userCanEdit"
              @click="isAddLineItemActive = true"
            ></b-button>
          </div>
          <div class="columns is-vcentered" v-if="!productItems.length">
            <div class="column">
              <b-message>
                <span class="mr-3">No Items</span>
                <b-button
                  class="is-small"
                  icon-left="plus"
                  :disabled="!userCanEdit"
                  @click="isAddLineItemActive = true"
                >
                  Add an Item
                </b-button>
              </b-message>
            </div>
          </div>
          <div v-else>
            <div class="columns" v-for="(group, groupKey) in groupedItems" :key="groupKey">
              <div class="column">
                <div class="my-1 is-size-5">{{ groupKey | capitalize }}</div>
                <div class="grouping">
                  <div
                    class="p-3 m-0 columns is-gapless is-vcentered"
                    v-for="(g, index) in group"
                    :key="g.id"
                    :class="{ 'is-even': index % 2 === 0, 'is-odd': index % 2 !== 0 }"
                  >
                    <div class="column is-9">
                      <div>
                        <span class="field-name">Name</span>
                        <router-link class="p-0 m-0" :to="`/catalog-item/${g.id}`">
                          <a>{{ g.name }}</a>
                        </router-link>
                      </div>
                      <div>
                        <span class="field-name">Cost</span>
                        <span>{{ (getCatalogItemCost(g).markupCost / 100) | money }}</span>
                      </div>
                    </div>
                    <div class="column is-2">
                      <b-field label="Qty">
                        <b-numberinput
                          min="0"
                          :step="getGroupConfig(groupKey).step"
                          :disabled="!userCanEdit"
                          v-model="g.quantity"
                        ></b-numberinput>
                      </b-field>
                    </div>
                    <div class="column is-1">
                      <div class="mr-1 is-pulled-right">
                        <div class="buttons">
                          <b-button
                            class="is-small is-danger"
                            icon-left="trash"
                            :disabled="!userCanEdit"
                            @click="removeLineItem(g)"
                          ></b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block" v-if="!isCostCode">
          <div class="my-3 sub-header">Cost</div>
          <div v-if="isServiceZone">
            <div class="columns">
              <div class="column">
                <b-field label="Markup Percentage"></b-field>
                <b-numberinput
                  step=".01"
                  min="0"
                  v-model="product.markupMultiplier"
                  icon="percentage"
                  placeholder=""
                  :disabled="!userCanEdit"
                  required
                ></b-numberinput>
              </div>
              <div class="column">
                <b-field label="Service Zone Cost"></b-field>
                <b-numberinput
                  min="0"
                  step="0.01"
                  v-model="tempServiceZoneCost"
                  icon="dollar-sign"
                  placeholder="Service Zone Cost"
                  :disabled="!userCanEdit"
                  required
                ></b-numberinput>
              </div>
            </div>
          </div>
          <div v-else-if="isAssessmentFee">
            <div class="columns">
              <div class="column">
                <b-field label="Assessment Fee Cost"></b-field>
                <b-numberinput
                  min="0"
                  step="0.01"
                  v-model="tempAssessmentFeeCost"
                  icon="dollar-sign"
                  placeholder=""
                  :disabled="!userCanEdit"
                  required
                ></b-numberinput>
              </div>
            </div>
          </div>
          <div v-else-if="isDiscount">
            <div class="columns">
              <div class="column">
                <b-field label="Percentage Amount">
                  <b-field grouped>
                    <b-numberinput
                      min="0"
                      step="1"
                      v-model="tempMarkdownPercentage"
                      icon="percentage"
                      :disabled="!userCanEdit"
                      controls-position="compact"
                      required
                    ></b-numberinput>
                    <p class="control">
                      <b-button @click="clearValue('tempMarkdownPercentage')" label="clear"></b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Amount">
                  <b-field grouped>
                    <b-numberinput
                      min="0"
                      step="0.01"
                      v-model="tempMarkdownCost"
                      icon="dollar-sign"
                      controls-position="compact"
                      :disabled="!userCanEdit"
                      required
                    ></b-numberinput>
                    <p class="control">
                      <b-button @click="clearValue('tempMarkdownCost')" label="clear"></b-button>
                    </p>
                  </b-field>
                </b-field>
              </div>
            </div>
          </div>
          <div v-else-if="!isCostCode">
            <div class="columns">
              <div class="column">
                <b-field>
                  <b-switch
                    v-model="product.publish"
                    true-value="Use 2a Detail Add Price"
                    false-value="Use 2b Detail Add Price"
                    :disabled="!userCanEdit"
                  >
                    Price Override
                  </b-switch>
                </b-field>
              </div>
            </div>
            <b-message v-if="priceOverrideSelected" type="is-warning">
              Pricing is determined by price override. Line Items will be ignored when calculating totals.
            </b-message>
            <b-message v-else type="is-info">Pricing is determined by line items added to the product.</b-message>
            <div class="columns">
              <div class="column is-half" v-if="priceOverrideSelected">
                <div class="columns">
                  <div class="column">
                    <b-field label="Taxable Override">
                      <b-numberinput
                        min="0"
                        step="0.01"
                        v-model="quickTaxableTemp"
                        icon="dollar-sign"
                        :disabled="!userCanEdit"
                        required
                      ></b-numberinput>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Nontaxable Override">
                      <b-numberinput
                        min="0"
                        step="0.01"
                        v-model="quickNonTaxableTemp"
                        icon="dollar-sign"
                        :disabled="!userCanEdit"
                        required
                      ></b-numberinput>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="column is-half" v-else>
                <div class="columns">
                  <div class="column">
                    <b-field label="Taxable Cost">
                      <b-input
                        type="text"
                        icon="dollar-sign"
                        v-model="cost.priceToCustomerCost.markupTaxable"
                        disabled
                      ></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Nontaxable Cost">
                      <b-input
                        type="text"
                        icon="dollar-sign"
                        v-model="cost.priceToCustomerCost.markupNonTaxable"
                        disabled
                      ></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-half">
                <span class="field-name">{{ priceOverrideSelected ? 'Override' : '' }} Price To Customer Total</span>
                <span class="is-pulled-right">
                  {{ priceOverrideSelected ? overrideTotal : cost.priceToCustomerCost.total | money }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column action-buttons">
            <b-button class="mr-2 is-danger" @click="goBack">Cancel</b-button>
            <button class="button is-success" type="submit" :disabled="!userCanEdit">
              {{ isNew ? 'Create' : 'Update' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { CatalogService as catalogShared } from '@newmoon-org/shared';
import { mapGetters } from 'vuex';

import CatalogItemsService from '@/service/catalog-items.service';
import CatalogService from '@/service/catalog.service';
import WorkOrderService from '@/service/workorders.service';
import {
  create as createNewProductArea,
  update as updateProductArea,
  getForCatalog,
} from '@/service/productAreas.service';

import AddProductAreaModal from '@/components/productAreas/AddProductAreaModal.vue';

export default {
  name: 'CatalogProductEditPage',
  components: {
    AddProductAreaModal,
  },
  data() {
    return {
      productCategoryToCreate: null,
      originalValues: {
        type: null,
        productCategory: null,
        productArea: null,
      },
      catalogTypesById: {},
      cost: {
        priceToCustomerCost: {},
      },
      groupConfig: {
        labor: { step: 0.1 },
      },
      isLoading: false,
      isProductAreaModalActive: false,
      isProductCategoryModalActive: false,
      isAddLineItemActive: false,
      selectedCatalogItem: null,
      searchLineItems: '',
      areaToCreate: { label: '' },
      categoryToCreate: { label: '' },
      tempNontaxableTotal: 0,
      tempTaxableTotal: 0,
      tempAssessmentFeeCost: 0,
      tempMarkdownCost: 0,
      tempMarkdownPercentage: 0,
      tempServiceZoneCost: 0,
      quickTaxableTemp: 0,
      quickNonTaxableTemp: 0,
      product: {
        category: null,
        productCategory: null,
        costCodeNumber: null,
        companies: [],
        productArea: null,
        items: [],
        status: 'Active',
        type: 'plumbing',
        catalog: '',
        quality: 'good',
        specification: 'common',
        markupMultiplier: 0,
        duration: 0,
        publish: 'Use 2b Detail Add Price',
        quickTaxable: 0,
        taxableTotal: 0,
        quickNonTaxable: 0,
        nonTaxableTotal: 0,
        trainingLink: '',
        specificLanguage: '',
        warrantyStatus: '',
        serviceZoneCost: 0,
        assessmentFeeCost: 0,
        markdownPercentage: 0,
        markdownCost: 0,
        catalogType: {},
        catalogProductType: 'standard catalog item',
      },
      catalogTypes: [
        { label: 'Estimate/Bid', value: 'estimate/bid' },
        { label: 'Kit', value: 'kit' },
        { label: 'Option', value: 'option' },
        { label: 'Standard Item', value: 'standard catalog item' },
        { label: 'Upgrade', value: 'upgrade' },
      ],
      qualities: [
        { label: 'Good', value: 'good' },
        { label: 'Better', value: 'better' },
        { label: 'Best', value: 'best' },
      ],
      specifications: [
        { label: 'Assessment Fee', value: 'assessment-fee' },
        { label: 'Common', value: 'common' },
        { label: 'Cost Code', value: 'cost-code' },
        { label: 'Warranty Discount', value: 'warranty-discount' },
        { label: 'Discount', value: 'discount' },
        { label: 'Service Zone', value: 'service-zone' },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'hasPermission']),
    areaCategories() {
      return this.selectedArea.categories ?? [];
    },
    selectedArea() {
      const area = this.productAreasMap[this.product.productArea?.id];
      return area ?? {};
    },
    productAreasMap() {
      return _.keyBy(this.productAreas, 'id');
    },
    userCanEdit() {
      return this.hasPermission('catalog-product:write');
    },
    userCanEditProductArea() {
      return this.hasPermission('product-area:write');
    },
    isServiceZone() {
      return this.product.specification === 'service-zone';
    },
    isAssessmentFee() {
      return this.product.specification === 'assessment-fee';
    },
    isDiscount() {
      return ['warranty-discount', 'discount'].includes(this.product.specification);
    },
    isCostCode() {
      return this.product.specification === 'cost-code';
    },
    showItems() {
      return !this.isAssessmentFee && !this.isDiscount && !this.isServiceZone && !this.isCostCode;
    },
    catalogAreas() {
      return _.keyBy(this.catalogTypesById[this.product.catalogType.id]?.areas || [], 'value') || {};
    },
    productItems() {
      return this.product.items ?? [];
    },
    overrideTotal() {
      const taxable = this.priceOverrideSelected ? this.product.quickTaxable / 100 : this.tempTaxableTotal;
      const nonTaxable = this.priceOverrideSelected ? this.product.quickNonTaxable / 100 : this.tempNontaxableTotal;
      return taxable + nonTaxable;
    },
    priceOverrideSelected() {
      return this.product.publish === 'Use 2a Detail Add Price';
    },
    id() {
      return this.$route.params.catalogProductId;
    },
    groupedItems() {
      return _.groupBy(this.product.items, 'type');
    },
    isNew() {
      return this.id === 'new' || this.id === 'copy';
    },
    isCopy() {
      return this.id === 'copy';
    },
    catalogItemIsSet() {
      return !_.isEmpty(this.selectedCatalogItem);
    },
    isFullScreen() {
      return this.$isMobile();
    },
  },
  watch: {
    'product.items': {
      handler() {
        const cost = CatalogService.catalogShared.getCatalogProductPrice(this.product);
        this.cost = {
          nonTaxableCost: (cost?.nonTaxableCost ?? 0) / 100,
          taxableCost: (cost?.taxableCost ?? 0) / 100,
          priceToCustomerCost: {
            markupTaxable: cost?.priceToCustomerCost?.markupTaxable / 100,
            markupNonTaxable: cost?.priceToCustomerCost?.markupNonTaxable / 100,
            total: cost?.priceToCustomerCost?.total / 100,
          },
        };
      },
      deep: true,
    },
    quickTaxableTemp: {
      handler(value) {
        this.product.quickTaxable = value * 100;
      },
      immediate: true,
    },
    quickNonTaxableTemp: {
      handler(value) {
        this.product.quickNonTaxable = value * 100;
      },
    },
    tempMarkdownCost: {
      handler(v) {
        this.product.markdownCost = v * 100;
      },
    },
    tempAssessmentFeeCost: {
      handler(v) {
        this.product.assessmentFeeCost = v * 100;
      },
    },
    tempServiceZoneCost: {
      handler(v) {
        this.product.serviceZoneCost = v * 100;
      },
    },
    tempMarkdownPercentage: {
      handler(v) {
        this.product.markdownPercentage = v;
      },
    },
  },
  asyncComputed: {
    companies: {
      async get() {
        return WorkOrderService.projectCompanies();
      },
      default: [],
    },
    types: {
      get() {
        return CatalogService.getTypes().then(it => {
          this.catalogTypesById = _.keyBy(it, 'id');
          return _.keyBy(it, 'value');
        });
      },
      default: {},
    },
    lineItemsList: {
      get() {
        return !_.isEmpty(this.searchLineItems)
          ? CatalogItemsService.list({
              pageSize: 20,
              page: 0,
              search: this.searchLineItems,
            }).then(r => r.data)
          : Promise.resolve([]);
      },
      default: [],
    },
    productAreas: {
      get() {
        const catalogId = this.product.catalogType?.id;
        return catalogId
          ? getForCatalog(catalogId).then(it => {
              const area = it.find(a => a.id === this.product.productArea?.id);
              this.product.productArea = area ? { id: area.id, label: area.label } : null;
              this.product.productCategory = area
                ? (area.categories ?? []).find(it => it === this.product.productCategory)
                : null;
              return it;
            })
          : [];
      },
      default: [],
    },
  },
  async mounted() {
    if (this.id === 'new') return;
    const idToFetch = this.id === 'copy' ? this.$route.query.catalogProductId : this.id;
    this.isLoading = true;
    const temp = await CatalogService.getById(idToFetch);
    this.product = { ...this.product, ...temp };
    this.originalValues = {
      type: this.product.type,
      productCategory: this.product.productCategory,
      productArea: this.product.productArea,
    };
    this.product.items = await CatalogItemsService.getItemsForProduct(this.product);
    this.product.duration = Number(this.product.duration || 0);
    this.setCostValues();
    this.isLoading = false;
  },
  methods: {
    clearValue(field) {
      this[field] = 0;
    },
    createProductCategory() {
      updateProductArea({
        id: this.selectedArea.id,
        categories: _.sortBy([...(this.selectedArea.categories ?? []), this.productCategoryToCreate]),
      })
        .then(() => {
          this.product.productCategory = this.productCategoryToCreate;
          this.productCategoryToCreate = null;
          this.isProductCategoryModalActive = false;
          this.$asyncComputed.productAreas.update();
        })
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to create category, ${e.message}`,
            type: 'is-danger',
            hasIcon: true,
            duration: 5000,
          })
        );
    },
    createProductArea(value) {
      createNewProductArea({
        ...value,
        catalogType: {
          id: this.product.catalogType.id,
        },
      })
        .then(it => {
          this.product.productArea = { id: it.id, label: it.label };
          this.isProductAreaModalActive = false;
          this.$asyncComputed.productAreas.update();
        })
        .catch(e =>
          this.$buefy.notification.open({
            message: `Failed to create area, ${e.message}`,
            type: 'is-danger',
            hasIcon: true,
            duration: 5000,
          })
        );
    },
    async goBack() {
      await this.$router.go(-1);
    },
    validate() {
      return true;
    },
    setCostValues() {
      this.tempNontaxableTotal = this.product.nonTaxableTotal ? this.product.nonTaxableTotal / 100 : 0;
      this.tempTaxableTotal = this.product.taxableTotal ? this.product.taxableTotal / 100 : 0;
      this.tempAssessmentFeeCost = this.product.assessmentFeeCost ? this.product.assessmentFeeCost / 100 : 0;
      this.tempMarkdownCost = this.product.markdownCost ? this.product.markdownCost / 100 : 0;
      this.tempServiceZoneCost = this.product.serviceZoneCost ? this.product.serviceZoneCost / 100 : 0;
      this.quickNonTaxableTemp = this.product.quickNonTaxable ? this.product.quickNonTaxable / 100 : 0;
      this.quickTaxableTemp = this.product.quickTaxable ? this.product.quickTaxable / 100 : 0;
      this.tempMarkdownPercentage = this.product.markdownPercentage ? this.product.markdownPercentage : 0;
    },
    setSelected(item) {
      this.selectedCatalogItem = item;
    },
    async addCatalogItem() {
      this.product.items.push({
        itemId: this.selectedCatalogItem.id,
        cost: Number(this.selectedCatalogItem.cost || 0),
        quantity: 1,
        ...this.selectedCatalogItem,
      });
      this.isAddLineItemActive = false;
      this.searchLineItems = '';
      this.selectedCatalogItem = null;
    },
    async removeLineItem(selectedItem) {
      this.product.items = [
        ...this.product.items.filter(item => {
          return item.itemId !== selectedItem.itemId;
        }),
      ];
    },
    async save(costUpdate = false) {
      if (!this.validate()) {
        this.$buefy.notification.open({
          message: 'Please fill out all required fields',
          type: 'is-warning',
          hasIcon: true,
          duration: 5000,
        });
        return;
      }
      const fn = this.isNew ? CatalogService.create : CatalogService.update;
      this.product.productCategory = this.areaCategories.find(it => it === this.product.productCategory);
      try {
        const r = await fn(this.addCatalogType(this.product));
        this.$buefy.toast.open({
          message: 'Successfully saved Product',
          type: 'is-success',
        });
        if (!costUpdate) {
          await this.goBack();
          return;
        }
        this.product = { ...r.data };
        this.setCostValues();
      } catch (e) {
        this.$buefy.notification.open({
          message: `There was an error saving product, ${e.message}`,
          type: 'is-danger',
          hasIcon: true,
          duration: 5000,
        });
      }
    },
    addCatalogType(product) {
      if (!product.type || product.catalogType.id) return product;
      const { id, name } = this.types[product.type];
      return {
        ...product,
        catalogType: {
          id,
          name,
        },
      };
    },
    getCatalogItemCost(item) {
      return catalogShared.getCatalogItemPrice(item);
    },
    getGroupConfig(type) {
      return this.groupConfig[type] ?? { step: 1 };
    },
  },
};
</script>

<style lang="scss">
#catalog-product-edit {
  max-width: 1500px;

  .sub-header {
    font-size: 2em;
  }

  .field-name {
    font-weight: bold;
    margin-right: 1em;
  }

  .grouping {
    border: #ddd solid 1px;
    border-radius: 5px;
  }

  .is-odd {
    background-color: #eff1fa;
  }

  .is-even {
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
