<template>
  <div id="catalog-edit">
    <div class="is-loading" v-if="isLoading">
      <b-progress></b-progress>
    </div>
    <div class="is-not-loading" v-else>
      <div class="header-container">
        <div class="header">{{ isNew ? 'New' : catalog.name }} Catalog</div>
      </div>
      <nav class="breadcrumb">
        <ul>
          <li>
            <router-link class="p-0 m-0" to="/catalog"><a>Catalog</a></router-link>
          </li>
          <li class="is-active">
            <router-link class="p-0 m-0" :to="`/catalog/${catalogId}`">
              <a>{{ isNew ? 'New Catalog' : `${catalog.name}` | truncate(30) }}</a>
            </router-link>
          </li>
        </ul>
      </nav>
      <form class="content" @submit.prevent="save">
        <div class="columns">
          <div class="column is-narrow">
            <b-field>
              <b-tooltip label="Will not be available to work orders unless selected" position="is-right" multilined>
                <b-switch v-model="catalog.woStatus">Active</b-switch>
              </b-tooltip>
            </b-field>
          </div>
          <div class="column">
            <b-field>
              <b-tooltip label="Will not be available to work orders unless selected" position="is-right" multilined>
                <b-switch v-model="catalog.isEmailActive">Email</b-switch>
              </b-tooltip>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Name" required>
              <b-input v-model="catalog.name" :disabled="!isNew" required></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Description" required>
              <b-input v-model="catalog.description" type="textarea"></b-input>
            </b-field>
          </div>
        </div>
        <div class="companies">
          <div class="is-size-4">Companies</div>
          <div class="py-2 is-flex is-flex-direction-column">
            <b-checkbox
              class="py-1"
              v-model="catalog.companies"
              v-for="c in companies"
              :key="c.id"
              :native-value="c.id"
              :value="c.id"
            >
              {{ c.company }} - {{ c.code }}
            </b-checkbox>
          </div>
        </div>
        <product-areas :catalog-id="catalogId" v-if="isFeatureEnabled('productAreas')"></product-areas>
        <div class="catalog-products">
          <div class="py-3 is-size-4">Products</div>
          <div class="new" v-if="isNew">No Products</div>
          <search-list
            v-else
            index-name="catalog"
            :facets="facets"
            :transform="transform"
            :search-function="searchFunction"
            :show-refinements="false"
          >
            <template #results="{ items }">
              <table class="table is-striped">
                <thead>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Area</th>
                  <th>Category</th>
                  <th>Taxable</th>
                  <th>Non Taxable</th>
                  <th>Price To Customer</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.objectID">
                    <td>
                      <a @click="goToCatalogItemEditPage(item)">
                        <ais-highlight attribute="name" :hit="item"></ais-highlight>
                      </a>
                    </td>
                    <td>{{ item.status }}</td>
                    <td>
                      <ais-highlight attribute="area" :hit="item"></ais-highlight>
                    </td>
                    <td>
                      <ais-highlight attribute="category" :hit="item"></ais-highlight>
                    </td>
                    <td>{{ item.markupTaxableFormatted }}</td>
                    <td>{{ item.markupNonTaxableFormatted }}</td>
                    <td>{{ item.totalFormatted }}</td>
                    <td>
                      <b-button
                        class="button is-small"
                        @click="goToCatalogItemEditPage(item)"
                        icon-right="edit"
                      ></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </search-list>
        </div>
        <div class="py-3 is-flex is-flex-direction-row is-justify-content-end">
          <b-button class="mr-1 is-danger" @click="cancel">Cancel</b-button>
          <button class="button is-success" type="submit">{{ isNew ? 'Create' : 'Save' }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash';
import { mapGetters } from 'vuex';

import CatalogTypeService from '@/service/catalogType.service';
import CompanyService from '@/service/company.service';

import SearchList from '@/components/list/searchList.vue';
import ProductAreas from '@/components/productAreas/ProductAreas.vue';

export default {
  name: 'CatalogEditPage',
  components: { ProductAreas, SearchList },
  data() {
    return {
      facets: [
        { facet: 'catalogTypeName', displayName: 'Catalog' },
        { facet: 'area', displayName: 'Area' },
        { facet: 'category', displayName: 'Category' },
        { facet: 'specification', displayName: 'Specification' },
      ],
      transform(items) {
        const moneyTransforms = [
          {
            value: 'priceToCustomerCost.markupTaxable',
            key: 'markupTaxableFormatted',
          },
          {
            value: 'priceToCustomerCost.markupNonTaxable',
            key: 'markupNonTaxableFormatted',
          },
          { value: 'priceToCustomerCost.total', key: 'totalFormatted' },
        ];
        return map(items, item => ({
          ...item,
          ...moneyTransforms.reduce(
            (acc, config) => ({
              ...acc,
              [config.key]: this.$options.filters.money(parseInt(get(item, config.value, 0), 10) / 100),
            }),
            {}
          ),
        }));
      },
    };
  },
  computed: {
    ...mapGetters('features', ['isFeatureEnabled']),
    catalogId() {
      return this.$route.params.catalogId;
    },
    isNew() {
      return this.catalogId === 'new';
    },
    isLoading() {
      return this.$asyncComputed.catalog.updating && this.$asyncComputed.companies.updating;
    },
    isValid() {
      return !!this.catalog.name;
    },
  },
  asyncComputed: {
    catalog: {
      get() {
        if (this.isNew) return { companies: [] };
        return CatalogTypeService.get(this.catalogId);
      },
      default: {
        companies: [],
      },
    },
    companies: {
      get() {
        return CompanyService.getAll();
      },
      default: [],
    },
  },
  methods: {
    searchFunction(helper) {
      const page = helper.getPage();
      if (!helper.state.facets.includes('catalogType.id')) {
        helper.state.facets.push('catalogType.id');
      }
      helper.addFacetRefinement('catalogType.id', this.catalog.id).setPage(page).search();
    },
    async save() {
      if (!this.isValid) {
        return this.$buefy.notification.open({
          message: `Please fill out the required fields`,
          type: 'is-danger',
          hasIcon: true,
          duration: 5000,
        });
      }
      if (this.isNew) {
        await CatalogTypeService.create(this.catalog);
      } else {
        await CatalogTypeService.update(this.catalogId, { ...this.catalog }, false);
      }
      await this.$router.push({ name: 'catalog' });
    },
    async cancel() {
      await this.$router.push({
        name: 'catalog',
      });
    },
    goToCatalogItemEditPage(catalogItem) {
      this.$router.push({
        name: 'catalog-product-edit',
        params: { catalogProductId: catalogItem.objectID },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
